export default {
  // ERROR MESSAGES
  fetchDataError: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
  updateDataError: 'Er is een fout opgetreden bij het updaten van de data. Probeer later het nog een keer.',
  saveDataError: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
  deleteDataError: 'Er is een fout opgetreden bij het deleten van de data. Probeer later het nog een keer.',
  uploadDataError: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer later het nog een keer.',
  resetPasswordError: 'Er is een fout opgetreden bij het resetten van de password. Probeer later het nog een keer.',
  // SUCCES MESSAGES
  updateDataSucces: 'De aanpassingen zijn met succes opgeslagen',
}
